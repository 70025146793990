.spinner-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}
.spinner-wrap.full {
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
}
.spinner-wrap.absolute {
  position: absolute;
  justify-content: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.spinner-wrap.center {
  justify-content: center;
}
.spinner-wrap.end {
  justify-content: flex-end;
}
.spinner-wrap.start {
  justify-content: flex-start;
}
.spinner-wrap.md .spinner {
  width: 35px;
  height: 35px;
}

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid var(--color-grey);
  border-radius: 50%;
  border-top-color: var(--color-main);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}