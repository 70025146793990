.news-card {
  width: 100%;
  height: 100%;
  padding: 0 0 5px 0;
  cursor: pointer;
}
.news-card:hover {
  filter: drop-shadow(0px 18px 80px rgba(91, 94, 143, 0.1));
}
.news-card__top {
  margin-bottom: 10px;
}
.news-card__img {
  position: relative;
  padding-bottom: 55.6%;
  margin-bottom: 16px;
}
.news-card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.news-card__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bcbfc2;
}
.news-card__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #262d33;
}