.no-connection {
  pointer-events: none;
}
.no-connection.--visible .no-connection__content {
  transform: translateY(0);
  opacity: 1;
}
.no-connection.--visible .no-connection__overlay {
  opacity: 0.6;
}
.no-connection__content {
  position: fixed;
  top: 30px;
  width: 100%;
  max-width: 600px;
  left: 50%;
  margin-left: -300px;
  z-index: 1000;
  padding: 0 20px;
  background-color: #FAE8A9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: 0.2s ease-in-out;
  transform: translateY(-100%);
}
.no-connection__overlay {
  background-color: #FFFFFF;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  content: "";
  z-index: 999;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.no-connection__text {
  color: #736125;
  font-size: 15px;
}
.no-connection svg {
  width: 40px;
  height: 40px;
}
.no-connection__icon {
  display: flex;
  align-items: center;
}