.skeleton-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eaeaea;
  background: linear-gradient(to right, #e1e1e1 8%, #d8d8d8 18%, #e1e1e1 33%);
  background-size: 1000px 104px;
  position: relative;
  content: "";
}
.skeleton-background.w-100 {
  width: 100%;
}
.skeleton-background.w-80 {
  width: 80%;
}
.skeleton-background.w-70 {
  width: 70%;
}
.skeleton-background.w-50 {
  width: 50%;
}
.skeleton-background.h-15 {
  height: 15px;
}
.skeleton-background.h-20 {
  height: 20px;
}
.skeleton-background.h-24 {
  height: 24px;
}
.skeleton-background.h-30 {
  height: 30px;
}
.skeleton-background.h-40 {
  height: 40px;
}
.skeleton-background.h-50 {
  height: 50px;
}
.skeleton-background.h-100 {
  height: 100% !important;
}
.skeleton-background:hover {
  box-shadow: none !important;
}

.p-abs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.color-transparent {
  color: transparent !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}