:root {
  --main-font: "Jeko", sans-serif;
  --main-font2: "TTTravels", sans-serif;
  --main-font3: "Kinetika", sans-serif;
  --main-font4: "Aldrich", sans-serif;
  --color-black: #000;
  --color-base: #fff;
  --color-bg: #faf5ea;
  --color-grey: #d9d9d9;
  --color-dark: #222;
  --color-main: #a81818;
  --color-red: #c21313;
}

body {
  color: var(--color-black);
  background: var(--color-bg);
  font-family: var(--main-font), sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  font-display: optional;
}

body.active {
  overflow-y: hidden;
}

.test {
  width: 100%;
}

.test-list {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.test-list li {
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
}

.test-list li:last-child {
  margin-bottom: 0;
}

.test-link {
  text-align: center;
  width: 100%;
  color: var(--color-base);
  font-weight: 700;
  font-size: 18px;
  border: 1px solid var(--color-base);
  padding: 8px 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-background-size: 400px 400px;
  -moz-background-size: 400px;
  -o-background-size: 400px;
  background-size: 400px;
  background: -webkit-linear-gradient(45deg, #b30111, #b30111 50%, #64010a);
  background: -moz-linear-gradient(45deg, #b30111 0, #b30111 50%, #64010a 100%);
  background: -o-linear-gradient(45deg, #b30111 0, #b30111 50%, #64010a 100%);
  background: linear-gradient(45deg, #b30111, #b30111 50%, #64010a);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.test-link:hover {
  -webkit-animation: animation 0.3s linear forwards;
  -moz-animation: animation 0.3s linear forwards;
  -o-animation: animation 0.3s linear forwards;
  animation: animation 0.3s linear forwards;
}

@-webkit-keyframes animation {
  0% {
    -webkit-background-size: 400px 400px;
    background-size: 400px;
  }
  to {
    -webkit-background-size: 600px 600px;
    background-size: 600px;
  }
}

@-moz-keyframes animation {
  0% {
    -moz-background-size: 400px;
    background-size: 400px;
  }
  to {
    -moz-background-size: 600px;
    background-size: 600px;
  }
}

@-o-keyframes animation {
  0% {
    -o-background-size: 400px;
    background-size: 400px;
  }
  to {
    -o-background-size: 600px;
    background-size: 600px;
  }
}

@keyframes animation {
  0% {
    -webkit-background-size: 400px 400px;
    -moz-background-size: 400px;
    -o-background-size: 400px;
    background-size: 400px;
  }
  to {
    -webkit-background-size: 600px 600px;
    -moz-background-size: 600px;
    -o-background-size: 600px;
    background-size: 600px;
  }
}

.banner-slider .swiper-button-next,
.banner-slider .swiper-button-prev {
  width: 48px;
  height: 48px;
  background: var(--color-dark);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.banner-slider .swiper-button-next:after,
.banner-slider .swiper-button-prev:after {
  content: none;
}

.banner-slider__img,
.banner-slider__img img {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.banner-slider__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-us__top {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.about-us__logo {
  margin-bottom: 32px;
}

.about-us__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 24px;
}

.about-us__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.about-us__wrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

/**/
@media (max-width: 1600px) {
  .about-us__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.about-card {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 48px 24px;
  background: #b30111;
  min-height: 430px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/**/
@media (max-width: 1600px) {
  .about-card {
    min-height: 250px;
  }

  .about-card .about-card__icon img {
    width: 50px;
  }
}

.about-card__title {
  font-weight: 800;
  font-size: 32px;
  line-height: 34px;
  color: var(--color-base);
}

.about-card__bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-card__num {
  font-style: italic;
  font-size: 24px;
  line-height: 26px;
  color: var(--color-base);
}

.about-wrapper__img {
  width: 100%;
  position: relative;
  height: 100%;
}

.about-wrapper__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.about-wrapper__top {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-wrapper__text {
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  color: var(--color-base);
}

/**/
@media (max-width: 1600px) {
  .about-wrapper__text {
    font-size: 30px;
    line-height: 40px;
  }
}

.about-wrapper__companies {
  max-width: 580px;
  width: 100%;
  padding: 40px 20px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  background: #dd0802;
  margin-right: 24px;
  min-height: 265px;
}

.about-wrapper__companies img {
  width: 272px;
  margin-bottom: 32px;
}

.about-wrapper__book {
  max-width: 430px;
  width: 100%;
  padding: 43px 32px;
  background: var(--color-base);
  position: relative;
  font-weight: 500;
  font-size: 36px;
  line-height: 60px;
  color: #828282;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.about-wrapper__book:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 160px;
  height: 172px;
  z-index: 2;
  background-image: url(../images/book.png);
}

/**/
@media (max-width: 1600px) {
  .about-wrapper__book {
    font-size: 30px;
    line-height: 40px;
  }
}

.about-description {
  background: var(--color-base);
  padding: 32px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.about-description p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  margin-bottom: 14px;
}

.about-description p:last-child {
  margin-bottom: 0;
}

.about-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(34, 34, 34, 0.5);
  z-index: 999;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.modal.open {
  visibility: visible;
  opacity: 1;
}

.modal-wrapper {
  min-width: 543px;
  width: 100%;
  height: auto;
  background: var(--color-base);
  padding: 30px 35px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  z-index: 1;
}

.modal-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 32px;
}

.modal-form {
  max-width: 344px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 14px;
}

.modal-city {
  font-weight: 500;
  color: #373737;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 6px;
}

.modal-top {
  margin-bottom: 64px;
}

.modal-box {
  margin-bottom: 32px;
}

.modal-input {
  background: #f0ece3;
  width: 100%;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  height: 56px;
  padding: 17px 24px;
  font-size: 16px;
  line-height: 22px;
  color: #373737;
  border: none;
}

.modal-input::-webkit-input-placeholder {
  color: #ccc;
}

img {
  max-width: 100%;
  height: auto;
}
.modal-input:-moz-placeholder,
.modal-input::-moz-placeholder {
  color: #ccc;
}

.modal-input:-ms-input-placeholder,
.modal-input::-ms-input-placeholder {
  color: #ccc;
}

.modal-input::placeholder {
  color: #ccc;
}

.modal .btn-main {
  width: 100%;
}

.catalog-wrapper__top {
  margin-bottom: 30px;
}

.catalog-wrapper__box {
  margin: 0 -6px;
}

.catalog-wrapper__box,
.catalog-wrapper__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.catalog-wrapper__item {
  background: #fffcf5;
  padding: 10px 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin: 0 6px;
}

.catalog-wrapper__name {
  font-size: 14px;
  line-height: 21px;
  color: #4f4f4f;
}

.catalog-wrapper__clear {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 10px;
  background-image: url(../icon/clear.svg);
  margin-left: 16px;
  background-position: center;
  cursor: pointer;
}

.catalog-wrapper__filter {
  font-family: var(--main-font2);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ff4646;
  margin-left: 36px;
  cursor: pointer;
}

.catalog-wrapper__inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 24px;
}

.catalog-wrapper__innerSlug {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 24px;
}

/**/
@media (max-width: 1500px) {
  .catalog-wrapper__inner {
    grid-template-columns: repeat(2, 1fr);
  }

  .catalog-wrapper__innerSlug {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

/**/
@media (max-width: 960px) {
  .catalog-wrapper__innerSlug {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (max-width: 560px) {
  .catalog-wrapper__innerSlug {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.catalog-filter {
  background: #fffcf5;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 32px;
}

.catalog-filter__box {
  margin-bottom: 48px;
}

.catalog-filter__title {
  font-size: 28px;
  line-height: 40px;
  color: #dd0802;
  font-family: var(--main-font3);
  margin-bottom: 12px;
}

@media (max-width: 1600px) {
  .catalog-filter__title {
    font-size: 22px;
  }
}

.catalog-filter__item {
  margin-left: 16px;
  margin-bottom: 16px;
}

.catalog-filter__item.--open .catalog-filter__items img {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.catalog-filter__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 12px;
}

.catalog-filter__items img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.catalog-filter__name {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #242424;
}

/**/
@media (max-width: 1600px) {
  .catalog-filter__name {
    font-size: 18px;
  }
}

.catalog-filter__list {
  padding-left: 32px;
}

.catalog-filter__list li {
  list-style: disc;
  color: #706f6d;
  margin-bottom: 12px;
}

/**/
@media (max-width: 1600px) {
  .catalog-filter__list li {
    margin-bottom: 8px;
  }
}

.catalog-filter__list li:last-child {
  margin-bottom: 12px;
}

.catalog-filter__link {
  font-family: var(--main-font3);
  font-size: 20px;
  line-height: 25px;
  color: #706f6d;
}

/**/
@media (max-width: 1600px) {
  .catalog-filter__link {
    font-size: 16px;
  }
}

.catalog-checkbox {
  padding-left: 16px;
}

.catalog-checkbox li {
  margin-bottom: 12px;
}

.catalog-checkbox li:last-child {
  margin-bottom: 0;
}

.catalog-checkbox__label {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.catalog-checkbox__border {
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #c8c8c8;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: block;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.catalog-checkbox__border:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  background-image: url(../icon/done.svg);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.catalog-checkbox__category {
  font-family: var(--main-font3);
  font-size: 20px;
  line-height: 25px;
  color: #242424;
  margin-left: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.catalog-checkbox__category:last-child {
  font-size: 16px;
  line-height: 20px;
}

.catalog-checkbox__input {
  position: absolute;
  opacity: 0;
}

.catalog-checkbox__input:checked ~ .catalog-checkbox__border {
  border-color: transparent;
  background: #dd0802;
}

.catalog-checkbox__input:checked ~ .catalog-checkbox__border:after {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.back-section {
  margin-bottom: 30px;
}

.back-section.mb-0 {
  margin-bottom: 0;
}

.back-section__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fffcf5;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  padding: 11px 19px;
}

.back-section__btn span {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-left: 18px;
  color: #a81818;
}

.vacancy-section__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 18px;
}

.vacancy-section__text {
  font-family: var(--main-font4);
  font-size: 20px;
  line-height: 26px;
  color: #6d6d6d;
}

.vacancy-wrapper {
  margin-top: 45px;
  max-height: 500px;
  overflow-y: auto;
  direction: rtl;
  scroll-behavior: smooth;
}

.vacancy-wrapper::-webkit-scrollbar {
  width: 14px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.vacancy-wrapper::-webkit-scrollbar-track {
  background: #fff;
}

.vacancy-wrapper::-webkit-scrollbar-thumb,
.vacancy-wrapper::-webkit-scrollbar-track {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

.vacancy-wrapper::-webkit-scrollbar-thumb {
  background: #dd0802;
}

.vacancy-wrapper::-webkit-scrollbar-thumb:hover {
  background: #dd0802;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.vacancy-wrapper__items {
  cursor: pointer;
  background: #fffcf5;
  padding: 32px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  margin-bottom: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 20px;
}

.vacancy-wrapper__items:last-child {
  margin-bottom: 0;
}

.vacancy-wrapper__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vacancy-wrapper__box.--start {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.vacancy-wrapper__name {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #222;
  margin-bottom: 24px;
}

/**/
@media (max-width: 1600px) {
  .vacancy-wrapper__name {
    font-size: 24px;
    line-height: 34px;
  }
}

.vacancy-wrapper__text {
  font-size: 18px;
  line-height: 24px;
  color: #222;
}

/**/
@media (max-width: 1600px) {
  .vacancy-wrapper__text {
    font-size: 16px;
    line-height: 20px;
  }
}

.contact-section__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 30px;
}

.contact-section__city li {
  margin-bottom: 14px;
}

.contact-section__subtitle {
  margin-bottom: 18px;
  font-size: 24px;
  line-height: 34px;
}

.contact-section__link {
  font-size: 20px;
  line-height: 30px;
  color: #dd0802;
}

.contact-section__box {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.contact-section__box:last-child {
  margin-bottom: 0;
}

.contact-section__box .contact-section__link {
  margin-bottom: 14px;
}

.contact-section__map svg {
  cursor: pointer;
}

.basket-section {
  margin-bottom: 40px;
}

.basket-total {
  background: #fffcf5;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 32px;
}

.basket-total__title {
  font-size: 32px;
  line-height: 40px;
  color: #b1b1b1;
  margin-bottom: 24px;
}

.basket-total__quantity {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.basket-total__top {
  margin-bottom: 110px;
}

.basket-total__text {
  font-size: 18px;
  line-height: 22px;
  color: #b1b1b1;
}

.basket-total__total {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #b1b1b1;
  margin-bottom: 12px;
}

.basket-total__total span {
  color: #dd0802;
}

.basket-total__button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 50px -12px 0;
}

.basket-total__button .btn-dark,
.basket-total__button .btn-red {
  margin: 0 12px;
  height: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 14px 24px;
}

.basket-total__button .btn-dark span,
.basket-total__button .btn-red span {
  color: var(--color-base);
}

.basket-top {
  background: #b30111;
  -webkit-border-radius: 24px 24px 0 0;
  -moz-border-radius: 24px 24px 0 0;
  border-radius: 24px 24px 0 0;
  padding: 18px 18px 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: -30px;
}

.basket-top .catalog-checkbox__border {
  width: 24px;
  height: 24px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.basket-top .catalog-checkbox__border:after {
  width: 16px;
  height: 16px;
  background-image: url(../icon/doneRed.svg);
  background-repeat: no-repeat;
}

.basket-top .catalog-checkbox__input:checked ~ .catalog-checkbox__border {
  background: var(--color-base);
}

.basket-top .catalog-checkbox__category {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-base);
}

.basket-top__delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.basket-top__delete img {
  margin-right: 4px;
}

.basket-top__delete span {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-base);
}

.basket-card {
  position: relative;
  background: #fffcf5;
  padding: 25px 22px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.basket-card .catalog-checkbox__border {
  width: 24px;
  height: 24px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.basket-card .catalog-checkbox__border:after {
  width: 16px;
  height: 16px;
  background-image: url(../icon/doneWhite.svg);
  background-repeat: no-repeat;
}

.basket-card__img {
  position: relative;
  margin: 0 40px;
  max-width: 287px;
  width: 100%;
  padding-bottom: 30%;
}

.basket-card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.basket-card__info {
  max-width: 540px;
  width: 100%;
}

.basket-card__price {
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #dd0802;
}

.basket-card__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #1e1e1e;
  margin-bottom: 12px;
}

.basket-card__text {
  font-size: 16px;
  line-height: 20px;
  color: #464646;
  margin-bottom: 24px;
}

.basket-card__category {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #222;
}

.basket-card__category span {
  color: #b1b1b1;
}

.basket-card__close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.basket-quantity {
  margin-bottom: 40px;
}

.basket-quantity,
.basket-quantity__add,
.basket-quantity__remove {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.basket-quantity__add,
.basket-quantity__remove {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 40px;
  width: 100%;
  height: 35px;
  font-weight: 500;
  font-size: 32px;
  color: var(--color-base);
  background: #b30111;
}

.basket-quantity__add {
  -webkit-border-radius: 0 45px 45px 0;
  -moz-border-radius: 0 45px 45px 0;
  border-radius: 0 45px 45px 0;
}

.basket-quantity__remove {
  -webkit-border-radius: 45px 0 0 45px;
  -moz-border-radius: 45px 0 0 45px;
  border-radius: 45px 0 0 45px;
}

.basket-quantity__zero {
  max-width: 106px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
}

.accordion {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accordion-header {
  background-color: #fffcf5;
  padding: 30px 24px;
  cursor: pointer;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  font-size: 32px;
  line-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/**/
@media (max-width: 1600px) {
  .accordion-header {
    font-size: 20px;
    line-height: 26px;
    padding: 10px 24px;
  }
}

.accordion-header span {
  font-weight: 600;
  margin-right: 24px;
}

.accordion-header img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

.accordion-header.active img {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-item {
  margin-bottom: 24px;
}

/**/
@media (max-width: 1700px) {
  .accordion-item {
    margin-bottom: 14px;
  }
}

/*.accordion-item:last-child {*/
/*    margin-bottom: 0*/
/*}*/

.accordion-body {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
  padding: 0;
  background: #fffcf5;
  margin-top: 10px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
}

.accordion-body p {
  font-size: 20px;
  line-height: 36px;
  margin: 0;
  padding: 30px 24px;
  color: #706f6d;
}

.accordion-body.active {
  height: auto;
}

.branch-section__title {
  font-weight: 800;
  font-size: 64px;
  line-height: 80px;
  color: #222;
  margin-bottom: 45px;
}

/**/
@media (max-width: 1600px) {
  .branch-section__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 26px;
  }
}

.branch-section__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  gap: 22px;
}

.branch-section__link {
  position: relative;
  width: 100%;
}

.branch-section__link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.5);
}

.branch-section__img {
  position: relative;
  width: 100%;
  height: 100%;
}

.branch-section__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.branch-section__imfo {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.branch-section__text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: var(--color-base);
}

.branch-section__item {
  max-width: 430px;
  width: 100%;
}

.branch-section__goodwell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  min-height: 300px;
  background: #b30111;
  background: -webkit-linear-gradient(45deg, #b30111, #b30111 50%, #64010a);
  background: -moz-linear-gradient(45deg, #b30111 0, #b30111 50%, #64010a 100%);
  background: -o-linear-gradient(45deg, #b30111 0, #b30111 50%, #64010a 100%);
  background: linear-gradient(45deg, #b30111, #b30111 50%, #64010a);
}

.branch-section__goodwell img {
  margin-bottom: 18px;
}

.branch-section__goodwell span {
  font-size: 24px;
  line-height: 30px;
  color: var(--color-base);
}

.news-single__img {
  position: relative;
  padding-bottom: 100%;
}

.news-single__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.news-single__date {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #dd0802;
  margin-bottom: 18px;
}

.news-single__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;
}

.news-single__subtitle {
  font-size: 24px;
  line-height: 30px;
  color: #222;
}

.news-single__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.news-single__wrap-left {
  width: 580px;
  min-width: 580px;
}

.news-single__wrap-right {
  padding-left: 25px;
  max-width: 580px;
}

.product-single__wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.product-single__wrapper .mySwiper2 {
  max-width: 580px;
  width: 100%;
  padding-bottom: 60px;
}

.product-single__wrapper .mySwiper2 .swiper-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--color-base);
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.product-single__wrapper .mySwiper2 .swiper-slide img {
  width: 410px;
  height: 485px;
}

.product-single__wrapper .swiper {
  height: 100%;
}

.product-single__wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-single__wrapper .swiper {
  width: 100%;
  height: 600px;
}

.product-single__wrapper .swiper-pagination {
  bottom: 0;
}

.product-single__wrapper
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.product-single__wrapper
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  background: #fffcf5;
  opacity: 1;
}

.product-single__wrapper .swiper-pagination-bullet-active {
  background: #dd0802 !important;
}

.product-single__wrapper .mySwiper {
  max-width: 130px;
  max-height: 540px;
  width: 100%;
  margin-right: 20px;
}

.product-single__wrapper .mySwiper .swiper-slide {
  padding: 20px;
  background: var(--color-base);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.product-single__wrapper .mySwiper .swiper-slide img {
  width: 100%;
  height: 100%;
}

.product-single__wrapper .mySwiper .swiper-slide {
  width: 100%;
  opacity: 1;
}

.product-single__wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-single__wrapper .swiper-button-next:after,
.product-single__wrapper .swiper-button-prev:after {
  content: none;
}

.product-single__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #1e1e1e;
  margin-bottom: 12px;
}

.product-single__subtitle {
  font-size: 18px;
  line-height: 22px;
  color: #464646;
  margin-bottom: 36px;
  white-space: pre-wrap;
}

.product-single__box {
  margin-bottom: 46px;
}

.product-single__text {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  color: #464646;
}

.product-single__list {
  padding-left: 30px;
  color: #464646;
  font-size: 18px;
  line-height: 26px;
}

.product-single__bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -3px;
}

.product-single__bottom button {
  margin: 0 3px;
}

.product-single__button {
  position: absolute;
  right: 0;
  bottom: -20px;
  max-width: 580px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px 0 0 auto;
  z-index: 1;
}

/**/
@media (max-width: 1700px) {
  .product-single__button {
    max-width: 440px;
  }
}

.product-single__next,
.product-single__prev {
  width: 66px;
  height: 66px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.1);
  background: var(--color-base);
}

.product-single__next,
.product-single__prev,
.product-slider {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-single__next,
.product-single__prev,
.product-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.product-card {
  position: relative;
  padding: 30px 30px 40px 30px;
  background: #fffcf5;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.product-card:hover .product-card__img img {
  transform: scale(1.1);
}

.product-card__type {
  position: absolute;
  top: 18px;
  left: 18px;
  background: var(--color-dark);
  padding: 6px 30px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-base);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.product-card__type.--sale {
  background: var(--color-red);
}

.product-card__img {
  padding-bottom: 100%;
  margin: 20px 0;
  position: relative;
}
.product-card__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: 0.5s ease-in-out;
}
.product-card__name {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 6px;
  color: #d9d9d9;
}

.product-card__info {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 24px;
}

.product-card__price {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #dd0802;
  position: absolute;
  bottom: 20px;
  left: 30px;
}

.product-section__wrapper {
  position: relative;
}

.product-section .product-section-prev {
  left: -30px;
}

.product-section .product-section-next {
  right: -30px;
}

.product-section .product-section-button {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 10;
}

.product-section .product-section-button,
.product-section .product-section-next,
.product-section .product-section-prev {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-section .product-section-next,
.product-section .product-section-prev {
  width: 60px;
  height: 60px;
  background: var(--color-base);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-filter: drop-shadow(0 0 15px #ddddcb);
  filter: drop-shadow(0 0 15px #ddddcb);
  cursor: pointer;
}

.product-section .product-section-next:after,
.product-section .product-section-prev:after {
  content: none;
}

.product-section .swiper-slide {
  height: auto !important;
}

.product-card-v2 {
  height: 100%;
}

.product-card-v2__img {
  padding: 30px;
  background: #fffcf5;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 12px;
  min-height: 500px;
}

.product-card-v2__box {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-dark);
  margin-bottom: 6px;
}

.product-card-v2__box span {
  color: #b1b1b1;
}

.news-card__img {
  position: relative;
  padding-bottom: 100%;
  margin-bottom: 8px;
}

.news-card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.news-card__date {
  font-size: 18px;
  line-height: 22px;
  color: #dd0802;
  margin-bottom: 8px;
}

.news-card__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 12px;
}

.news-card__subtitle {
  font-size: 16px;
  line-height: 20px;
  color: #222;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-section__wrapper {
  position: relative;
}

.news-section .news-section-prev {
  left: -30px;
}

.news-section .news-section-next {
  right: -30px;
}

.news-section .news-section-button,
.news-section .news-section-next,
.news-section .news-section-prev {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-section .news-section-next,
.news-section .news-section-prev {
  width: 60px;
  height: 60px;
  background: var(--color-base);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-filter: drop-shadow(0 0 15px #ddddcb);
  filter: drop-shadow(0 0 15px #ddddcb);
  cursor: pointer;
}

.news-section .news-section-next:after,
.news-section .news-section-prev:after {
  content: none;
}

.news-section .swiper-slide {
  height: auto !important;
}

.category-card {
  cursor: pointer;
  position: relative;
  padding: 30px;
  outline: none;
  background: #fffcf5;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.category-card__img {
  margin-bottom: 30px;
}

.category-card__title {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 18px;
  line-height: 20px;
  color: var(--color-dark);
  white-space: nowrap;
}

.category-section__wrapper {
  position: relative;
}

.category-section .category-section-prev {
  left: -30px;
}

.category-section .category-section-next {
  right: -30px;
}

.category-section .category-section-button {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 10;
}

.category-section .category-section-button,
.category-section .category-section-next,
.category-section .category-section-prev {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-section .category-section-next,
.category-section .category-section-prev {
  width: 60px;
  height: 60px;
  background: var(--color-base);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-filter: drop-shadow(0 0 15px #ddddcb);
  filter: drop-shadow(0 0 15px #ddddcb);
  cursor: pointer;
}

.category-section .category-section-next:after,
.category-section .category-section-prev:after {
  content: none;
}

.category-section .swiper-slide {
  height: auto !important;
}

.media-card {
  position: relative;
  cursor: pointer;
}

.media-card:hover .media-card__img img {
  transform: scale(1.2);
}

.media-card,
.media-card__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.media-card__box {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.media-card__box span {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.media-card__play {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.media-card__img {
  position: relative;
  padding-bottom: 65%;
  width: 100%;
}

.media-card__img img {
  transition: 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

@font-face {
  font-family: Jeko;
  src: local("Jeko Regular"), local("Jeko-Regular"),
    url(../fonts/Jeko/Jeko-Regular.woff2) format("woff2"),
    url(../fonts/Jeko/Jeko-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Jeko;
  src: local("Jeko Medium"), local("Jeko-Medium"),
    url(../fonts/Jeko/Jeko-Medium.woff2) format("woff2"),
    url(../fonts/Jeko/Jeko-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Jeko;
  src: local("Jeko SemiBold"), local("Jeko-SemiBold"),
    url(../fonts/Jeko/Jeko-SemiBold.woff2) format("woff2"),
    url(../fonts/Jeko/Jeko-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Jeko;
  src: local("Jeko Bold"), local("Jeko-Bold"),
    url(../fonts/Jeko/Jeko-Bold.woff2) format("woff2"),
    url(../fonts/Jeko/Jeko-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Jeko;
  src: local("Jeko Black"), local("Jeko-Black"),
    url(../fonts/Jeko/Jeko-Black.woff2) format("woff2"),
    url(../fonts/Jeko/Jeko-Black.woff) format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: TTTravels;
  src: local("TTTravels Medium"), local("TTTravels-Medium"),
    url(../fonts/TTTravels/TTTravelsMedium.woff) format("woff"),
    url(../fonts/TTTravels/TTTravelsMedium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Kinetika;
  src: local("Kinetika Regular"), local("Kinetika-Regular"),
    url(../fonts/Kinetika/Kinetika-Regular.woff2) format("woff"),
    url(../fonts/Kinetika/Kinetika-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Aldrich;
  src: local("Aldrich Regular"), local("Aldrich-Regular"),
    url(../fonts/Aldeich/AldrichRegular.woff) format("woff"),
    url(../fonts/Aldeich/AldrichRegular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.footer {
  margin-top: 80px;
  padding: 60px 0;
  background: var(--color-dark);
  color: var(--color-base);
}

.footer__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__wrap-right {
  max-width: 670px;
  width: 100%;
  padding-left: 100px;
}

.footer__wrap-left {
  max-width: 570px;
  width: 100%;
}

.footer-subtitle {
  font-weight: 800;
  font-size: 64px;
  line-height: 79px;
  margin-bottom: 24px;
}

.footer-text {
  max-width: 605px;
  width: 100%;
  font-size: 24px;
  line-height: 29px;
}

.footer-form {
  max-width: 430px;
  width: 100%;
  margin: 50px 0;
}

.footer-textarea {
  height: 120px;
}

.footer-input,
.footer-textarea {
  background: transparent;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #717277;
  padding: 15px 0;
  resize: none;
  font-size: 24px;
  color: var(--color-bg);
}

.footer-input::-webkit-input-placeholder,
.footer-textarea::-webkit-input-placeholder {
  color: #717277;
  font-size: 24px;
  line-height: 29px;
}

.footer-input:-moz-placeholder,
.footer-input::-moz-placeholder,
.footer-textarea:-moz-placeholder,
.footer-textarea::-moz-placeholder {
  color: #717277;
  font-size: 24px;
  line-height: 29px;
}

.footer-input:-ms-input-placeholder,
.footer-input::-ms-input-placeholder,
.footer-textarea:-ms-input-placeholder,
.footer-textarea::-ms-input-placeholder {
  color: #717277;
  font-size: 24px;
  line-height: 29px;
}

.footer-input::placeholder,
.footer-textarea::placeholder {
  color: #717277;
  font-size: 24px;
  line-height: 29px;
}

.footer-box {
  margin-bottom: 30px;
}

.footer .btn-main {
  max-width: 280px;
  width: 100%;
}

.footer-mini {
  max-width: 450px;
  width: 100%;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 24px;
}

.footer-copyright {
  margin-bottom: 48px;
}

.footer-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -24px;
}

.footer-icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0 24px;
}

.footer-icon.--instagram {
  background-image: url(../icon/social1.svg);
}

.footer-icon.--telegram {
  background-image: url(../icon/social2.svg);
}

.footer-icon.--google {
  background-image: url(../icon/social3.svg);
}

.footer-icon.--facebook {
  background-image: url(../icon/social4.svg);
}

.footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.footer-wrapper .footer-mini {
  max-width: 100%;
}

.footer-wrapper .footer-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-width: 100%;
}

.footer-logo {
  margin-bottom: 60px;
}

.footer-center {
  margin-top: 80px;
}

.footer-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 100px;
}

.footer-item:last-child {
  margin-bottom: 0;
}

.footer-type {
  max-width: 260px;
  width: 100%;
  color: var(--color-base);
  white-space: nowrap;
  margin-right: 30px;
}

.footer-link,
.footer-type {
  font-size: 24px;
  line-height: 34px;
}

.footer-link {
  color: #5f5f5f;
  margin-bottom: 18px;
}

.footer-link:last-child {
  margin-bottom: 0;
}

.footer-df {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.header {
  padding: 32px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  transition: 0.3s ease-in-out;
}
.header.--hide {
  transform: translateY(-100%);
  opacity: 0;
}

#page-wrapper {
  padding-top: 120px;
}

.header-wrapper {
  max-height: 64px;
}

.header-logo,
.header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-logo {
  width: 150px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 17px 24px;
  -webkit-border-radius: 24px 0 0 24px;
  -moz-border-radius: 24px 0 0 24px;
  border-radius: 24px 0 0 24px;
  min-height: 64px;
}

.header-list,
.header-logo {
  background: var(--color-dark);
}

.header-list {
  padding: 0 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.header-link {
  color: var(--color-base);
  padding: 20px 10px;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
}

.header-link,
.header-search {
  background: var(--color-dark);
}

.header-search {
  position: relative;
  padding: 12px 24px;
}

.header-search__wrapper {
  position: relative;
}

.header-search img {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-input {
  height: 40px;
  border: none;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  padding: 11px 0 11px 50px;
  font-size: 14px;
  line-height: 18px;
}

.header-input::-webkit-input-placeholder {
  color: #b8b8b8;
}

.header-input:-moz-placeholder,
.header-input::-moz-placeholder {
  color: #b8b8b8;
}

.header-input:-ms-input-placeholder,
.header-input::-ms-input-placeholder {
  color: #b8b8b8;
}

.header-input::placeholder {
  color: #b8b8b8;
}

.header-category {
  padding: 20px 24px;
  background: var(--color-main);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
}

.header-category.--radius {
  -webkit-border-radius: 0 24px 24px 0;
  -moz-border-radius: 0 24px 24px 0;
  border-radius: 0 24px 24px 0;
}

.header-category img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.header-category span {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-base);
}

/**/
@media (max-width: 1700px) {
  .header-category {
    padding: 20px 14px;
  }

  .header-category img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .header-category span,
  .header-link {
    font-size: 16px;
  }

  .header-link {
    padding: 20px 10px;
  }

  .header-search {
    padding: 12px 14px;
  }

  .header .language-text span {
    font-size: 18px;
  }
}

/**/
@media (max-width: 1500px) {
  .header {
    padding: 24px 0 !important;
  }

  .header-logo {
    min-height: 52px;
    width: 130px;
    padding: 14px;
  }

  .header-category {
    padding: 14px;
  }

  .header-category img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .header-category span,
  .header-link {
    font-size: 14px;
  }

  .header-link {
    padding: 14px 10px;
  }

  .header-search {
    padding: 11px 14px;
  }

  .header-input {
    height: 30px;
  }

  .header .language .language-toggle {
    padding: 14px 0 14px 10px;
  }

  .header .language-text span {
    font-size: 14px;
  }
}

.language {
  position: relative;
}

.language-list {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--color-base);
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.14);
  z-index: 100;
}

.language-list li:not(:last-child) {
  margin-bottom: 4px;
}

.language-list.open {
  top: 75px;
  opacity: 1;
  visibility: visible;
}

.language-list.open ~ .language-toggle .language-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.language-link {
  cursor: pointer;
  position: relative;
  color: var(--color-black);
  font-weight: 500;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: transparent;
  width: 100%;
  padding: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.language-link.active,
.language-link:hover {
  background: rgba(0, 0, 0, 0.14);
  color: var(--color-black);
}

.language-text span {
  color: var(--color-base);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 7px;
}

.language .language-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 0 20px 20px;
  background: var(--color-dark);
  -webkit-box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.14);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar,
html::-webkit-scrollbar-track {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

html::-webkit-scrollbar-track {
  background: #fff;
}

html::-webkit-scrollbar-thumb {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

html::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb:hover {
  background: #00000030;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

a {
  display: inline-block;
  color: var(--color-dark);
  font-family: var(--main-font);
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  background: transparent;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

.page-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.page-wrapper .page-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.sec-padding {
  margin: 80px 0;
}

/**/
@media (max-width: 1400px) {
  .sec-padding {
    margin: 60px 0;
  }
}

.container {
  max-width: 1812px;
  margin: 0 auto;
  padding: 0 45px;
}

.btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-main {
  padding: 13px 20px;
  background: #b30111;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.btn-main span {
  font-size: 18px;
  line-height: 22px;
  color: var(--color-base);
}

.btn-red {
  background: #dd0802;
  padding: 13px 20px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  height: 50px;
}

.btn-red img {
  margin-left: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.btn-red span {
  font-size: 16px;
  line-height: 20px;
  color: var(--color-base);
}

.btn-dark {
  background: #222;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 10px;
}

.btn-dark img {
  width: 30px;
  height: 30px;
}

.m-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 26px;
}

.m-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-dark);
}

@media (min-width: 1600px) {
  .m-title.--big {
    font-size: 64px;
    line-height: 80px;
  }
}

.m-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-red);
  text-decoration: underline;
}
