.block-no-data {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 30px;
  background-color: #fffcf5;
  text-align: center;
}
.block-no-data.--big-image {
  padding: 0;
  background-color: transparent;
}
.block-no-data.--big-image img {
  width: 100%;
}
.block-no-data img {
  width: 200px;
  margin-bottom: 20px;
}
.block-no-data .--title {
  font-size: 32px;
  margin-bottom: 10px;
  color: #373737;
}
.block-no-data .--description {
  font-weight: 400;
  margin-bottom: 40px;
  font-size: 20px;
  color: #373737;
}
.block-no-data .--link {
  color: #373737;
  text-decoration: underline;
}