.mobile-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 78px;
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 11;
}
.mobile-navigation__item:nth-child(2) {
  background-color: #ffffff;
  border-radius: 100px;
  width: 54px;
  height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: var(--color-red);
}
.mobile-navigation__item:nth-child(2) span {
  height: 24px;
}
.mobile-navigation__item:nth-child(1), .mobile-navigation__item:nth-child(3) {
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
}
.mobile-navigation__item:nth-child(1) span, .mobile-navigation__item:nth-child(3) span {
  margin-bottom: 8px;
  content: "";
  width: 38px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-navigation__item:nth-child(1) span svg path, .mobile-navigation__item:nth-child(3) span svg path {
  fill: var(--color-red);
}

.mob-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 78px;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.3s ease-in-out;
  pointer-events: none;
}
.mob-menu.--open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
.mob-menu__link:not(:last-child) {
  color: #373737;
  font-size: 28px;
  margin: 20px 0;
}
.mob-menu__link:last-child {
  width: 100%;
  background-color: var(--color-red);
  font-size: 22px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}
.mob-menu__link:last-child img {
  margin-right: 15px;
}
.mob-menu .mob-menu-phone {
  color: #DD0802;
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 500;
}