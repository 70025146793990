.banner-slider .swiper-slide {
  opacity: 0.5;
  transition: 0.5s ease-out;
}
.banner-slider .swiper-slide.swiper-slide-active {
  transition: 0.5s ease-in;
  opacity: 1;
}
.banner-slider .swiper-button-prev {
  position: absolute;
  left: 12px;
  top: 50%;
  z-index: 1;
  margin-top: -24px;
}
.banner-slider .swiper-button-next {
  position: absolute;
  right: 12px;
  top: 50%;
  z-index: 1;
  margin-top: -24px;
}

.banner-slider__img {
  display: block;
  padding-bottom: 26%;
  width: 100%;
  position: relative;
}
.banner-slider__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.category-card__img img {
  max-width: 190px;
  max-height: 190px;
}

.category-section .category-section-prev,
.category-section .category-section-next,
.product-section-prev,
.product-section-next {
  bottom: 50%;
  margin-bottom: -30px;
  z-index: 10;
}

.news-section-prev, .news-section-next {
  z-index: 2;
  opacity: 0;
  transform: translateY(-50%);
}

.product-card__info {
  color: var(--color-dark);
}

.media-card__img {
  overflow: hidden;
  border-radius: 24px;
}
.media-card__img:after {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.media-card__box {
  color: var(--color-base);
}

.media-section-prev, .media-section-next {
  width: 60px;
  height: 60px;
  background: var(--color-base);
  border-radius: 50%;
  justify-content: center;
  filter: drop-shadow(0 0 15px #ddddcb);
  cursor: pointer;
}

.media-section-next,
.media-section-prev {
  position: absolute;
  bottom: 50%;
  margin-bottom: -30px;
  z-index: 10;
}

.media-section__wrapper {
  position: relative;
}

.swiper-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.swiper-btn-white {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: var(--color-base);
  border-radius: 50%;
  filter: drop-shadow(0 0 15px #ddddcb);
  z-index: 10;
  user-select: none;
}
.swiper-btn-white.--default {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
}
.swiper-btn-white.--next {
  right: 0;
  margin-right: -30px;
}
.swiper-btn-white.--prev {
  left: 0;
  margin-left: -30px;
}

.vacancy-wrapper__box {
  width: 33%;
}
.vacancy-wrapper__box:last-child {
  align-items: flex-start;
  text-align: right;
}

.news-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.news-list .news-card {
  width: 33.333%;
  padding: 0 10px;
  margin-bottom: 30px;
}

.contact-section__link {
  cursor: pointer;
}
.contact-section__link.--active {
  text-decoration: underline;
  color: var(--color-dark);
}

.contact-section__map svg {
  max-width: 100%;
}

.contact-row {
  display: flex;
}
.contact-row .contact-row__col:nth-child(1) {
  width: 400px;
  min-width: 400px;
}
.contact-row .contact-row__col:nth-child(2) {
  flex: 1;
  padding: 0 60px;
}
.contact-row .contact-row__col:nth-child(3) {
  width: 300px;
  min-width: 300px;
  padding-right: 50px;
}

.contact-section__map svg {
  display: block;
}
.contact-section__map .custom-tooltip {
  position: absolute;
  transform: translate(-50%, -100%);
  width: 270px;
  background: #fff;
  color: #5F5F5F;
  padding: 15px 30px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 20px;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.contact-section__map .custom-tooltip.--active {
  opacity: 1;
}

.catalog-section-wrap {
  display: flex;
}

.catalog-section-wrapSlug {
  width: 100%;
}
.catalog-section-wrap__aside {
  width: 400px;
}
.catalog-section-wrap__content {
  width: calc(100% - 400px);
  padding-left: 30px;
}

.catalog-filter__item.--open .catalog-filter__list {
  opacity: 1;
  pointer-events: auto;
  max-height: 1000px;
}
.catalog-filter__item.--open .catalog-filter__items {
  margin-bottom: 12px;
}

.catalog-filter__list {
  opacity: 0;
  transition: 0.2s ease-in-out;
  max-height: 0;
  pointer-events: none;
}

.catalog-filter__items {
  margin-bottom: 0;
  transition: margin-bottom 0.2s ease-in-out;
}

.catalog-filter__name, .catalog-filter__items img {
  user-select: none;
}

.catalog-filter__link {
  user-select: none;
  cursor: pointer;
}
.catalog-filter__link:hover {
  color: #dd0802;
}

.catalog-wrapper__clear {
  transition: 0.2s ease-in-out;
}
.catalog-wrapper__clear:hover {
  transform: scale(1.3);
}

.catalog-wrapper__box {
  flex-wrap: wrap;
}

.catalog-wrapper__item, .catalog-wrapper__filter {
  margin-bottom: 10px;
}

.catalog-wrapper__top {
  display: flex;
  justify-content: space-between;
}

.custom-dropdown {
  position: relative;
  z-index: 5;
}
.custom-dropdown.--open .custom-dropdown__value:after {
  width: 100%;
}
.custom-dropdown.--open .custom-dropdown__list {
  opacity: 1;
  transform: translateY(-15px);
  pointer-events: auto;
}
.custom-dropdown__list {
  position: absolute;
  top: 100%;
  transform: translateY(-30px);
  background-color: #ffffff;
  padding: 22px 12px 12px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  border-radius: 0 0 12px 12px;
}
.custom-dropdown__list > div {
  cursor: pointer;
  padding: 10px 0;
}
.custom-dropdown__list > div:hover {
  color: #dd0802;
}
.custom-dropdown__value {
  z-index: 2;
  color: #7D7D7D;
  background-color: #ffffff;
  height: 48px;
  border-radius: 10px;
  width: 280px;
  display: flex;
  align-items: center;
  padding: 0 70px 0 12px;
  position: relative;
  cursor: pointer;
}
.custom-dropdown__value span {
  z-index: 1;
  position: relative;
}
.custom-dropdown__value:after {
  transition: 0.3s ease-in-out;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #FFDEDD;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  content: "";
}
.custom-dropdown__icon {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-image: url("../../assets/icon/arrow-up.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.product-single {
  display: flex;
  align-items: flex-start;
}
.product-single__info {
  max-width: 750px;
  padding-left: 30px;
}

.basket-top__delete {
  cursor: pointer;
}

.modal-request {
  background-color: #FFFCF5;
  padding: 30px 35px;
}
.modal-request__title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px;
}
.modal-request__form {
  padding: 0 40px;
}
.modal-request__form .mod-main-input:first-child {
  margin-bottom: 65px;
}
.modal-request .mod-select__control {
  background-color: #F0ECE3;
  border-radius: 25px !important;
  border-color: #e6e8ec !important;
}
.modal-request .mod-main-input__input {
  border-radius: 25px;
  background-color: #F0ECE3;
  height: 56px;
  font-size: 18px;
  color: #999999;
}
.modal-request .mod-main-input__input::placeholder {
  color: #CCCCCC;
}
.modal-request .btn-red {
  width: 100%;
  margin-top: 48px;
}

.youtube-video-modal {
  padding-bottom: 56%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.youtube-video-modal iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.btn.--loading {
  color: transparent !important;
  position: relative;
}
.btn.--loading * {
  opacity: 0;
}
.btn.--loading:after {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  animation: 0.8s spin linear infinite;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.product-single__slider {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-left: 140px;
  width: 630px;
}
.product-single__slider .swiper-horizontal {
  width: 100%;
}
.product-single__slider .product-main-slider {
  width: 530px;
  height: 605px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFCF5;
}
.product-single__slider .product-main-slider img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-single__slider .swiper-thumbs {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-right: 25px;
}
.product-single__slider .swiper-thumbs .swiper-slide {
  width: 125px;
  height: 140px;
  cursor: pointer;
  background-color: #FFFCF5;
  border-radius: 15px;
  overflow: hidden;
}
.product-single__slider .swiper-thumbs .swiper-slide img {
  object-fit: contain;
  width: 125px;
  height: 140px;
}

.header-logo, .header-category {
  z-index: 1;
}

.header-wrapper {
  position: relative;
}

.header-category {
  cursor: pointer;
  user-select: none;
}

.header-category__img {
  width: 30px;
  display: flex;
  justify-content: center;
}

.categories-dropdown {
  position: absolute;
  background-color: #FAF5EA;
  padding: 44px 0 10px;
  left: 0;
  top: 50%;
  border-radius: 20px;
  width: 100%;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.06);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-30px);
  transition: 0.2s ease-in-out;
}
.categories-dropdown.--open {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.categories-dropdown > div {
  position: relative;
}
.categories-dropdown > div:hover .children-menu {
  pointer-events: auto;
  transform: translateX(0);
  opacity: 1;
}
.categories-dropdown > div .parent-item {
  cursor: pointer;
  padding: 15px 30px;
  color: #373737;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.categories-dropdown > div .parent-item img {
  margin-right: 10px;
  width: 18px;
}
.categories-dropdown > div .parent-item:hover {
  color: var(--color-red);
}
.categories-dropdown > div .children-menu {
  position: absolute;
  left: 100%;
  top: 0;
  padding-left: 10px;
  transform: translateX(-20px);
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  min-width: 220px;
}
.categories-dropdown > div .children-menu .wrap {
  background-color: #FAF5EA;
  border-radius: 10px;
  padding: 10px 0;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.06);
}
.categories-dropdown > div .children-menu .wrap > div {
  cursor: pointer;
  padding: 15px 30px;
  color: #373737;
  font-size: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.categories-dropdown > div .children-menu .wrap > div:hover {
  color: var(--color-red);
}
.categories-dropdown > div .children-menu .wrap > div img {
  margin-right: 10px;
  width: 18px;
}

.filter-button {
  position: fixed;
  left: 50%;
  bottom: 78px;
  transform: translateX(-50%);
  background-color: #F3EAD4;
  z-index: 10;
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 54px;
  border-radius: 25px 25px 0 0;
}
.filter-button img {
  margin-right: 10px;
}

.close-filter-button {
  position: absolute;
  right: 15px;
  top: 25px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.mt-30 {
  margin-top: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.basket-total {
  position: sticky;
  top: 20px;
}

.basket-card__text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-card__type {
  z-index: 1;
}

.catalog-filter__items .category-icon {
  display: none;
  /* width: 24px; */
}

.product-card__add-to-cart {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #222222;
  width: 60px;
  height: 60px;
  border-radius: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 4;
}
.product-card__add-to-cart.--success {
  background-color: #FCF5E5;
}
.product-card__add-to-cart img {
  max-width: 25px;
}

.product-card__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 3;
}

.product-card__old-price {
  position: absolute;
  bottom: 50px;
  left: 30px;
  text-decoration: line-through;
  opacity: 0.5;
  font-size: 18px;
  font-weight: 500;
}

.product-single__price {
  font-size: 40px;
  color: var(--color-red);
  margin-bottom: 30px;
}

.product-single__old-price {
  margin-bottom: 10px;
  font-size: 24px;
  text-decoration: line-through;
  opacity: 0.5;
}